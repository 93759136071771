import { useAuthentication } from '@livekatsomo/web/data-hooks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

export interface EmailNotVerifiedProps {
  email: string;
}

/**
 * Renders a component that displays a message indicating that the user's email is not yet verified.
 * Provides options to resend verification email, reload the page, and sign out.
 * @param props - The component props.
 * @param props.email - The email address of the user.
 * @returns The rendered component.
 */
export function EmailNotVerified({ email }: EmailNotVerifiedProps) {
  const { requestEmailVerification, logout } = useAuthentication();
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h4" component="h1">
        {t('Email {{email}} waiting to be verified', { email })}
      </Typography>
      <Typography variant="body1">
        {t(
          'Used email is not yet verified. Please check your email and press the verify link.',
        )}
      </Typography>
      <Typography variant="subtitle1">
        {t('Already verified your email? Please reload the page.')}
      </Typography>
      <Button variant="contained" onClick={() => router.reload()}>
        {t('Reload')}
      </Button>
      <Typography variant="body2">
        {t(
          'If you have not received your verification email, you can resend it.',
        )}
      </Typography>
      <Button
        variant="outlined"
        onClick={() => requestEmailVerification(email)}
      >
        {t('Resend verification email')}
      </Button>
      <Button variant="text" onClick={logout}>
        {t('Sign Out')}
      </Button>
    </Box>
  );
}
