import { Customer } from '@livekatsomo/models';
import { LiveAvatar } from '@livekatsomo/web/ui-components/avatar';
import { Ribbon } from '@livekatsomo/web/ui-components/layout';
import { NextLinkComposed } from '@livekatsomo/web/ui-components/link';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

/**
 * Props for the StorePreviewCard component.
 */
export interface StorePreviewCardProps {
  customer: Customer;
}

/**
 * Renders a preview card for a customer's store.
 *
 * @param {StorePreviewCardProps} props - The props for the component.
 * @param {Customer} props.customer - The customer object containing store information.
 * @returns The rendered preview card.
 */
export function StorePreviewCard({ customer }: StorePreviewCardProps) {
  const { t } = useTranslation();
  if (!customer.poster) return null;
  return (
    <Card
      component={NextLinkComposed}
      to={`/${customer?.slug}`}
      sx={{
        textDecoration: 'none',
        position: 'relative',
        overflow: 'visible',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        borderRadius: '16px',
      }}
    >
      <Image
        src={
          customer.poster?.downloadUrls?.jpeg500x500 ||
          customer.poster?.originalUrl
        }
        fill={true}
        blurDataURL={customer.poster?.blurDataURL}
        placeholder={customer.poster?.blurDataURL ? 'blur' : 'empty'}
        alt={customer.poster?.alt || 'Poster'}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 0,
          borderRadius: '8px',
          objectFit: 'cover',
        }}
        sizes="20vw"
      />
      <CardHeader
        data-chromatic="ignore"
        color="palette.common.white"
        sx={{
          zIndex: 1,
          background: 'rgba(0, 0, 0, 0.2)',
          willChange: 'auto',
          backdropFilter: 'blur(5px)',
          '& .MuiCardHeader-title': {
            fontWeight: 'bold',
          },
          '& .MuiTypography-root': {
            color: (theme) => theme.palette.common.white,
            textShadow: '0px 0px 10px rgb(0 0 0 / 80%)',
          },
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
        avatar={<LiveAvatar asset={customer.logo} name={customer.name} />}
        title={customer.name}
        subheader={customer.description}
      />
      {customer?.customerType === 'shop' ? (
        <Ribbon position="top-left" color="primary">
          {t('Shop')}
        </Ribbon>
      ) : null}
    </Card>
  );
}

export default StorePreviewCard;
