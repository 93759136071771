import { User } from '@livekatsomo/models';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Renders a component that displays a message indicating that the user has no email to be verified.
 * Provides an option to add an email input and update the user profile.
 * @param props - The component props.
 * @param {User} props.user - The user object.
 * @returns The rendered component.
 */
export function NoEmailToBeVerified({ user }: { user: User }) {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h4" component="h1">
        {t('Page requires your email to be verified')}
      </Typography>
      <Typography variant="body1">
        {t('Please add your email to your account.')}
      </Typography>

      {/* TODO: Add email input and user profile update*/}
    </>
  );
}
