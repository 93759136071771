import { CustomerDoc } from '@livekatsomo/models';
import { DebugDetails } from '@livekatsomo/web/ui-components/debug-details';
import ChannelsCard from '../ChannelsCard/ChannelsCard';

/**
 * Props for the ChannelsView component.
 */
export interface ChannelsViewProps {
  customers: CustomerDoc[];
}

/**
 * Renders a view of channels for a list of customers.
 * @param customers - An array of CustomerDoc objects.
 * @returns A React component that displays the channels for the given customers.
 */
export function ChannelsView({ customers }: ChannelsViewProps) {
  return (
    <>
      <DebugDetails data={customers} />
      <ChannelsCard customers={customers}></ChannelsCard>
    </>
  );
}

export default ChannelsView;
