import { CustomerDoc } from '@livekatsomo/models';
import { LiveAvatar } from '@livekatsomo/web/ui-components/avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

/**
 * Props for the ChannelsCard component
 */
export interface ChannelsCardProps {
  /**
   * An array of customer documents to display in the channels card
   */
  customers?: CustomerDoc[];
}

/**
 * A card component that displays a list of channels (customers)
 */
export function ChannelsCard({ customers }: ChannelsCardProps) {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {t('Channels')}
        </Typography>
        <List>
          {customers
            ? customers.map((customer) => (
                <ListItemButton
                  key={customer.id}
                  LinkComponent={Link}
                  href={`/${customer.slug}`}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <LiveAvatar name={customer.name} asset={customer.logo} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <p>
                          {customer.name}
                          {customer.customerType === 'shop' ? (
                            <Chip
                              component="span"
                              size="small"
                              sx={{ marginLeft: 1 }}
                              label={t('Shop')}
                              color="primary"
                            />
                          ) : null}
                        </p>
                      }
                      secondary={customer.description}
                    />
                  </ListItem>
                </ListItemButton>
              ))
            : null}
        </List>
      </CardContent>
    </Card>
  );
}

export default ChannelsCard;
