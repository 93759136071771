import { CustomerDoc } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import { useTheme } from '@mui/material/styles';
import { useRef } from 'react';
import { Autoplay, Navigation, Pagination, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import StorePreviewCard from '../StorePreviewCard/StorePreviewCard';

/**
 * Object containing breakpoints for the Swiper component.
 * Each breakpoint specifies the number of slides per view at a certain screen width.
 */
const breakpoints = {
  600: {
    slidesPerView: 2,
    // spaceBetween: 20,
  },
  900: {
    slidesPerView: 3,
    // spaceBetween: 40,
  },
  1200: {
    slidesPerView: 3,
    // spaceBetween: 50,
  },
  1536: {
    slidesPerView: 4,
    // spaceBetween: 50,
  },
};

/**
 * Props for the StoresPreviewSwiper component.
 */
export interface StoresPreviewSwiperProps {
  customers?: CustomerDoc[];
}

/**
 * Renders a Swiper component that displays a preview of customer stores.
 * @param customers An array of `CustomerDoc` objects to display in the Swiper.
 * @returns A `StoresPreviewSwiper` component.
 */
export function StoresPreviewSwiper({ customers }: StoresPreviewSwiperProps) {
  const ref = useRef<HTMLElement>(null);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        gap: 2,
        '& .swiper-button-disabled': {
          opacity: 0,
        },
        overflow: 'visible',
      }}
    >
      <NoSsr>
        <Swiper
          spaceBetween={Number(theme.spacing(1).slice(0, -2))}
          virtual={true}
          style={{
            flex: 1,
            // Width is set to 100% to prevent the swiper from overflowing the container
            width: '100%',
          }}
          autoplay={true}
          breakpoints={breakpoints}
          preventClicks={false}
          modules={[Pagination, Navigation, Autoplay, Virtual]}
          navigation={true}
          pagination={{
            el: ref.current,
            type: 'bullets',
            clickable: true,
          }}
        >
          {customers &&
            customers.map((customer, index) => (
              <SwiperSlide key={customer.id} virtualIndex={index}>
                <Box sx={{ padding: 1 }}>
                  <StorePreviewCard customer={customer} />
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>
      </NoSsr>
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          textAlign: 'center',
          transform: 'translateY(100%)',
          display: ['none', 'block'],
        }}
      />
    </Box>
  );
}
