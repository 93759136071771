import { useAuthentication } from '@livekatsomo/web/data-hooks';
import {
  SignedInSuccesfully,
  SigninView,
} from '@livekatsomo/web/ui-components/authentication';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AuthenticationErrorViewer } from '@livekatsomo/web/features/authentication-error-viewer';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

export interface SignInFeatureProps {
  onSuccesfulSignin?: () => void;
}

/**
 * Renders a sign-in feature that allows users to sign in with various authentication providers.
 * @param props - The component props.
 * @param props.onSuccesfulSignin - A callback function to be called when the user successfully signs in.
 * @returns The sign-in feature component.
 */
export function SignInFeature({ onSuccesfulSignin }: SignInFeatureProps) {
  const {
    user,
    error,
    processing,
    setError,
    setProcessing,
    facebookSignIn,
    googleSignIn,
    twitterSignIn,
    signInWithEmailAndPassword,
  } = useAuthentication();
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { redirectTo, email } = router.query as {
    redirectTo?: string;
    email?: string;
  };

  // Call onSuccesfulSignin callback function if provided or
  // redirect to home or redirectTo page when user logged in
  useEffect(() => {
    if (user && !user.isAnonymous) {
      if (onSuccesfulSignin) onSuccesfulSignin();
      if (redirectTo) {
        router.push(redirectTo);
      } else if (router.pathname === '/sign-in') {
        router.push('/');
      }
    }
  }, [router, redirectTo, user, onSuccesfulSignin]);

  if (user && !user.isAnonymous) {
    return <SignedInSuccesfully />;
  }

  const handleGoogleSignIn = async () => {
    try {
      setProcessing('google.com');
      setError(null);
      await googleSignIn();
      onSuccesfulSignin && onSuccesfulSignin();
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };
  const handleFacebookSignIn = async () => {
    try {
      setProcessing('facebook.com');
      setError(null);
      await facebookSignIn();
      onSuccesfulSignin && onSuccesfulSignin();
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };
  const handleTwitterSignIn = async () => {
    try {
      setProcessing('twitter.com');
      setError(null);
      await twitterSignIn();
      onSuccesfulSignin && onSuccesfulSignin();
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };
  const handleSignInWithEmailAndPassword = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      setProcessing('password');
      setError(null);
      await signInWithEmailAndPassword(email, password);
      onSuccesfulSignin && onSuccesfulSignin();
      enqueueSnackbar(t('Signed in successfully'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('Sign in failed'), {
        variant: 'error',
      });
      setError(error as Error);
    } finally {
      setProcessing(null);
    }
  };

  return (
    <SigninView
      email={email}
      errorMessage={
        error ? <AuthenticationErrorViewer error={error} email={email} /> : null
      }
      processing={processing}
      onGoogleSignIn={handleGoogleSignIn}
      onFacebookSignIn={handleFacebookSignIn}
      onTwitterSignIn={handleTwitterSignIn}
      onSignInWithEmailAndPassword={handleSignInWithEmailAndPassword}
    />
  );
}
