import { CustomerDoc } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StoresPreviewSwiper } from './StoresPreviewSwiper';

/**
 * Props for the StoresPreview component.
 */
export interface StoresPreviewProps {
  /**
   * The title of the component.
   */
  title: string;
  /**
   * An optional array of customer documents.
   */
  customers?: CustomerDoc[] | null;
}

/**
 * Renders a preview of stores with a given title and list of customers.
 * @param props - The component props.
 * @param props.title - The title to display above the stores preview.
 * @param {Array} props.customers - The list of customers to display in the stores preview.
 * @returns The rendered component.
 */
export function StoresPreview({ title, customers }: StoresPreviewProps) {
  if (!customers || customers.length === 0) return null;

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          alignSelf: 'flex-start',
          textShadow: '0px 0px 10px rgb(0 0 0 / 80%)',
        }}
      >
        {title}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <StoresPreviewSwiper customers={customers} />
      </Box>
    </>
  );
}
