export interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

/**
 * Renders an error message and a button to reset the error boundary.
 * @param props - The component props.
 * @param {Error} props.error - The error object to display.
 * @param props.resetErrorBoundary - The function to reset the error boundary.
 * @returns The rendered component.
 */
export function ErrorFallback({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
