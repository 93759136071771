import {
  EmailNotVerifiedError,
  KatsomoError,
  KatsomoErrorCodes,
} from '@livekatsomo/custom-errors';
import { SignInFeature } from '@livekatsomo/web/features/sign-in';
import { UnauthorizedUserErrorView } from '@livekatsomo/web/ui-components/authentication';
import { Component, ErrorInfo, ReactNode } from 'react';
import { EmailNotVerified } from '../EmailNotVerified/EmailNotVerified';

export interface FallbackProps {
  error: Error;
  children?: ReactNode;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
  FallbackComponent?: React.ComponentType<FallbackProps>;
  onReset?: (...args: Array<unknown>) => void;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

/**
 * ErrorBoundary component that catches errors in its child components and displays a fallback UI.
 * @component
 * @param {ErrorBoundaryProps} props - Component props
 * @param {React.ReactNode} props.children - Child components to render
 * @param {React.ComponentType<FallbackProps>} [props.FallbackComponent] - Fallback component to render when an error occurs
 * @param [props.onReset] - Function to call when the error is reset
 * @example
 * return (
 *   <ErrorBoundary FallbackComponent={ErrorFallback}>
 *     <MyComponent />
 *   </ErrorBoundary>
 * );
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  errorNode: ReactNode;

  public override state: ErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(
      'Errorboundary caught ungaught error:',
      error,
      error.message,
      errorInfo,
    );
  }

  public reset() {
    this.setState({ hasError: false, error: undefined });
  }

  public resetErrorBoundary = (...args: Array<unknown>) => {
    this.props.onReset?.(...args);
    this.reset();
  };

  public override render() {
    const { FallbackComponent } = this.props;
    const error = this.state.error;

    if (error) {
      if (error instanceof EmailNotVerifiedError) {
        const data = error.data;
        this.errorNode = <EmailNotVerified {...data} />;
      }

      if (error instanceof KatsomoError) {
        const errorCode = error.code;
        switch (errorCode) {
          case KatsomoErrorCodes.SLUG_NOT_FOUND:
            this.errorNode = (
              <h1>{`Data by id "${
                (error.data as { slug: string }).slug
              }" is not found.`}</h1>
            );
            break;
          case 'permission-denied':
            // How to handle permission denied error?
            // 1. check if user is logged in
            // 2. if user is logged in, then show error message
            // 3. if user is not logged in, then show login screen
            this.errorNode = <UnauthorizedUserErrorView />;
            break;

          case KatsomoErrorCodes.USER_TOKEN_EXPIRED:
            this.errorNode = (
              <SignInFeature onSuccesfulSignin={this.resetErrorBoundary} />
            );
            break;

          case KatsomoErrorCodes.AUTHENTICATION_REQUIRED:
            this.errorNode = (
              <SignInFeature onSuccesfulSignin={this.resetErrorBoundary} />
            );
            break;
          case 'not-found':
            this.errorNode = <h1>Data is not found.</h1>;
            break;

          default:
            break;
        }
      }

      if (FallbackComponent) {
        return (
          <FallbackComponent
            error={error}
            resetErrorBoundary={this.resetErrorBoundary}
            children={this.errorNode}
          />
        );
      }

      return this.errorNode ? (
        this.errorNode
      ) : (
        <h1 data-testid="errorboundary">
          Sorry... there was an error: {this.state.error?.message}
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
